;var JsSearch = function (options, window, $) {
    var _this = this;

    this.defaultProducts = options.defaultProducts || null;
    this.defaultLabels = options.defaultLabels || null;

    this.setInputWidth = function(){
        $(".search-inputs-wrapper").width($('#header-search').width());
    }

    this.hide = function() {
        $('#search-backdrop').removeClass('active');
        $('body').css({overflow: 'initial'})
        $(".search-inputs-wrapper").css({
            'position': 'initial',
            'z-index': 201,
        });
        $(".search-typeahead-list").hide();
        $('#search-field').blur();
    }

    this.show = function () {
        $('body').css({overflow: 'hidden'})
        $("#search-backdrop").addClass('active');
        $(".search-inputs-wrapper").css({
            'position': 'absolute',
            'z-index': 201,
        });
        $(".search-typeahead-list").css({'display':'flex'});
        this.setInputWidth();
    }


    $("body").on('keyup', function(e){
        var code = e.keyCode || e.which;
        if (code === 27 && $('#search-backdrop').is(':visible')){
            _this.hide();
        }
    });

    $("#search-form").on('submit', function(e){

        if ($('.search-typeahead-list .search-nav.active').length > 0) {
            var href = $('.search-typeahead-list .search-nav.active').attr('href');
            window.location = href;
            return false;
        } else {
            return true;
        }

    });

    _this.requestTimeout = null;
    _this.runSearch = function(e) {
        clearTimeout(_this.requestTimeout);
        var code = e.keyCode || e.which;

        if (code === 13) {
            return;
        }

        var links = $('.search-typeahead-list').find('.search-nav');
        if (code === 40 || code === 39) {
            let activeKey = null;
            links.each(function(k, v){
                if ($(v).hasClass('active')){
                    activeKey = k;
                    $(v).removeClass('active');
                }
            });
            if (activeKey !== null) {
                if (links[activeKey + 1]) {
                    activeKey = activeKey + 1;
                } else {
                    activeKey = 0;
                }
            } else {
                activeKey = 0;
            }
            $(links[activeKey]).addClass('active');
        } else if (code === 38 || code === 37) {
            let activeKey = null;
            links.each(function(k, v){
                if ($(v).hasClass('active')){
                    activeKey = k;
                    $(v).removeClass('active');
                }
            });
            if (activeKey !== null) {
                if (links[activeKey - 1]) {
                    activeKey = activeKey - 1;
                } else {
                    activeKey = links.length - 1;
                }
            } else {
                activeKey = links.length - 1;
            }
            $(links[activeKey]).addClass('active');
        } else {
            clearTimeout(_this.requestTimeout);
            _this.requestTimeout = setTimeout(function () {
                var searchTerm = $('#search-field').val();
                if (searchTerm.length > 2) {
                    $.ajax({
                        url: '/product/default/search-ajax',
                        type: 'get',
                        dataType: 'json',
                        data: {
                            search_term: searchTerm,
                        },
                        success: function(result) {
                            if (result.total.count){
                                _this.renderProducts(result.products, searchTerm);
                                _this.renderLabels(result.labels, searchTerm);
                                _this.renderProductMoreItems(result.total);
                            } else {
                                _this.renderProductNoItems();
                            }
                        }
                    });
                } else {
                    _this.renderDefaultSearch();
                }

            }, 500);
        }
    };

    $("body").on('keyup', '#search-field', _this.runSearch);

    this.renderProducts = function (products, term) {
        var template = new JsTemplate('product-item');
        var productViews = "";
        for (var i = 0; i < products.length; i++) {
            var product = products[i];
            product.title = product.title.replace(new RegExp(term + '(?!([^<]+)?<)', 'gi'), '<span class="hl">$&</span>');
            template.replace(product);
            productViews += template.content;
            template.reset();
        }
        var template = new JsTemplate('product-more-item');

        $('.search-product-container ul').html(productViews);
        $(".search-typeahead-list").find('img[data-srcs]').each(function(k,v){
            $(v).prop('src', $(v).data('srcs'));
        });
    }

    this.renderLabels = function (labels, term) {
        var template = new JsTemplate('label-item');
        var labelViews = "";
        for (var i = 0; i < labels.length; i++) {
            var label = labels[i];
            label.name = label.name.replace(new RegExp(term + '(?!([^<]+)?<)', 'gi'), '<span class="hl">$&</span>');
            template.replace(label);
            labelViews += template.content;
            template.reset();
        }
        if ($('.search-label-container:hidden')) {
            $('.search-label-container').show();
            $('.search-product-container').removeClass('no-prod');
        }
        $('.search-label-container ul').html(labelViews);
    }

    this.renderProductNoItems = function (){
        var template = new JsTemplate('product-no-item');
        $('.search-label-container').hide();
        $('.search-product-container').addClass('no-prod');
        $('.search-product-container ul').html(template.content);
    }

    this.renderProductMoreItems = function (total) {
        var template = new JsTemplate('product-more-item');
        template.replace({
            totalCount: total.count,
            url: total.url,
        });
        $('.search-product-container ul').append(template.content);
    }

    this.renderDefaultSearch = function () {
        _this.renderProducts(_this.defaultProducts);
        _this.renderLabels(_this.defaultLabels);
    }


    $('.mobile-search-trigger').on('click', function() {
        $('#mobile-search').addClass('active');
    });
    $('.moblie-search-close').on('click', function() {
        $('#mobile-search').removeClass('active');
        _this.hide();
    });
    $("body").on('keyup', function(e){
        var code = e.keyCode || e.which;
        if (code === 27 && $('#mobile-search').is(':visible')){
            $('#mobile-search').removeClass('active');
        }
    });


    $("body").on('focus', '#search-field', function(e){
        if ($(this).val() !== '') {
            _this.runSearch(e)
        }
        _this.show();
    });

    $('#search-backdrop').on('click', function(){
        _this.hide();
    });

    $(window).resize(function(e) {
        _this.setInputWidth();
    });

    if ($('#search-field').val() === ''){
        _this.renderDefaultSearch();
    }
};